<template>
  <v-card>
    <v-card-title> Create Supplier </v-card-title>
    <v-card-text>
      <h1 class="text-center black--text text-h5 text-uppercase">
        Company Information
      </h1>
      <validation-observer ref="company_observer" v-slot="{ invalid }">
        <form @submit.prevent="submit">
          <v-container>
            <v-row>
              <v-col md="6" sm="12" lg="6" xl="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Corporation Name"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.corporation"
                    :error-messages="errors"
                    label="Corporation Name"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Business Name"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.business_name"
                    :error-messages="errors"
                    label="Business Name"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Delivery Address"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.delivery_address"
                    :error-messages="errors"
                    label="Delivery Address"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="City"
                  rules="required"
                >
                  <country-region-data-dropdown
                    clearable
                    label="City"
                    v-model="formData.city"
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Zipcode"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.zipcode"
                    :error-messages="errors"
                    label="Zipcode"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Phone Number"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.phone_number"
                    :error-messages="errors"
                    label="Phone Number"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Fax"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.fax"
                    :error-messages="errors"
                    label="Fax"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <!--  -->
              <v-col md="6" sm="12" lg="6" xl="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.email"
                    :error-messages="errors"
                    label="Email"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Type of Business"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.business_type"
                    :error-messages="errors"
                    label="Type of Business"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Employer Identification Number (EIN)"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.ein"
                    :error-messages="errors"
                    label="Employer Identification Number (EIN)"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Registro de Comerciante"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.merchant_registration"
                    :error-messages="errors"
                    label="Registro de Comerciante"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Date of Business Establishment"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.date_business_establishment"
                    :error-messages="errors"
                    label="Date of Business Establishment"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Description of place of Business"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.business_description"
                    :error-messages="errors"
                    label="Description of place of Business"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text type="submit" :disabled="invalid"
            >Create</v-btn
          >
          <v-btn text color="red">Cancel</v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required /*  digits, regex */ } from "vee-validate/dist/rules";
import CountryRegionDataDropdown from "../../components/common/CountryRegionDataDropdown.vue";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  data() {
    return {
      formData: {
        corporation: "",
        business_name: "",
        delivery_address: "",
        city: "",
        zipcode: "",
        phone_number: "",
        fax: "",
        email: "",
        business_type: "",
        ein: "",
        merchant_registration: "",
        date_business_establishment: "",
        business_description: "",
      },
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    CountryRegionDataDropdown,
  },
  methods: {
    submit() {},
  },
};
</script>

<style>
</style>